<template>
    <cms-block v-bind="$props" @block-selected="selectBlock" :style="blockStyle">
        <cms-block-selector v-for="child in block.children" :key="child.id" @block-selected="selectBlock" :block="child" is-child no-padding :block-selection="blockSelection" :editable="editable"/>
    </cms-block>
</template>

<script>
import CmsBlock from "@/components/editor/rendering/blocks/cms-block.vue";

// cms-block-cards #hot-reload-debug
export default {
    name: `cms-block-cards`,
    components: {
        CmsBlockSelector: () => import(`@/components/editor/rendering/blocks/cms-block-selector.vue`),
        CmsBlock
    },
    extends: CmsBlock,
    computed: {
        blockStyle() {
            let style = {
                "--list-item-spacing": this.block.mediaProxy.spacing,
                "--list-item-columns": this.block.mediaProxy.columns
            }
            return style;
        }
    }
}
</script>

<style lang="scss" scoped>
.cms-block-cards {
    --list-item-columns: 1;
    --list-item-spacing: 10px;

    ::v-deep .cms-block {
        //margin-bottom: var(--list-item-spacing);
        break-inside:avoid;
    }

    .cms-list-item {
        width: 100%;
    }

    ::v-deep > .cms-container {
        display: grid;
        grid-template-columns: repeat(var(--list-item-columns), 1fr);
        grid-gap: var(--list-item-spacing);
    }
}
</style>
